body {
    padding-top: 0rem;
    padding-bottom: 0rem;
color: #5a5a5a;
text-align:left;
min-height: 0;

}

.bg-orange{
	background:orange;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    margin: 0% 5%;
	width:90%
}

.main-content{
	/* margin-top:4%; */
	margin-bottom:4%;
}

.sub-banner{
	width:100%;
	background:#333;
	height:200px;
}

.enquiry_form{
	text-align:left;
	color:#CCC;
}

.main-footer{
	color:#CCC;
	display:flex;
}

.pull-right {
	float:right;
}

.pull-left {
	float:left;
}

.back-btn a{
	position:absolute;
	left:90px;
	/* margin-top:14px; */
	font-size:30px;
	transition:all 0.7s;
	cursor:pointer;
	color:#333 !important;

}

.back-btn a:hover{
	color:#CCC !important;
}

.font-head{
	font-size:18px;
	font-weight:600;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.0rem;
    padding-left: 1.0rem;
	color:#FFF;
}

.green-bg{
	background:#2f880f;
}

.bottom-nav{
	position:fixed;
	bottom:0px;
	width:100%;
	z-index:999;
}

.search_page .form-check{
	float: left;
    margin-right: 100px;
}

.form-check-input:checked{
	background-color: #2f880f;
    border-color: #2f880f;
}

.navbar-dark .navbar-nav .nav-link {
    color: #FFF;
}

.lite-text{
	color:#FFF !important;
}


.gray-text{
	color:#999 !important;
}

.dark-text{
	color:#111 !important;
}

.lite-bg{
	background:#FFF !important;
}


.gray-bg{
	background:#CCC !important;
}

.dark-bg{
	background:#111 !important;
}

.code-details textarea{
	width:100%;
}

.detail-box{
	min-height:100px;
	overflow-y:scroll;
	text-align:left;
	font-size: 12px;
}

.scan-box{
	text-align:center;
	font-size:58px;
	padding:40px;
	color:#CCC;
}


.detail-box::-webkit-scrollbar {
  width: 0.7em;
}
 
.detail-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.detail-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.ocr-view button{
	background:#1e3615;
	border:none;
}

.ocr-view button:hover{
	background:#2f880f;
	color:#FFF;
}


.captured-image button{
	/*background:#1e3615;*/
	border:none;
}

.captured-image button:hover{
	background:#2f880f;
	color:#FFF;
}

.btn-color{
	background:#1e3615;
	border:none;
}

.carousel {
    margin-bottom: 1rem;
}

.active{
	color:#000 !important;
	font-weight:bold;
}

.active a{
	color:#000 !important;
	font-weight:bold;
}


@media screen and (max-width: 768px){
	.navbar-nav .nav-item a {
    color: #FFF;
    border-bottom: solid 1px #439824 !important;
    padding-left: 0px !important;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 0rem !important;
}

}

.navbar-dark .navbar-toggler {
    color: #FFF;
    border-color: #FFF;
}

.capture-thumbs img{
	width:90px !important;
	border:solid 3px #FFF;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
border-radius:5px;
float:left;
margin-right:10px;
cursor:pointer;
}

.capture-thumbs img:hover{
	opacity:0.5;
}

input:focus{
	outline:none !important;
}

.nutrition_facts .table>:not(caption)>*>* {
    padding: 0px 5px;
	font-size: 12px;
    vertical-align: middle;
}

.nutrition_facts .form-control {
    padding: 0px 5px !important;
}

.ocr-view input{border-radius:0px;}

.form-control:focus{
	outline:none !important;
}
.bg-padding {
    padding: 0px;
}


.k-pager-numbers .k-link.k-selected {
	color: blue !important;
	background-color: lightblue;
}

.k-pager-numbers .k-link{
	color: blue !important;

}
.buttonsuccess{
	background:#2f880f !important;
	color:#FFF;
	
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel, .k-pager-numbers .k-link.k-selected { 
    background-color: green !important;
}

.modal-header {
	background: green !important;
	color: #FFF;
}

.app-modal-body {
	text-align: center;
}
