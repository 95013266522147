/* @import "~bootstrap/dist/css/bootstrap.min.css"; */
@import "~bootstrap-icons/font/bootstrap-icons"; 
@import "~font-awesome/css/font-awesome.min.css";
body{
    height:100vh;
    width:100%;
    overflow-x: hidden;
    margin:0px;
}

 
/* You can add global styles to this file, and also import other style files */
/** Header Styles Start Here **/
.navbar-header {
    background-color: #2f880f;
   /* color: white;
    */
    width: 100%;
    height: 37px;
    color: white;
}
.navbar-header .top-menu {
    margin: 0px;
}
.navbar-header .glyphicon-question-sign:before {
    margin-right: 5px;
}
img {
    padding: 0 16px;
}
.logo {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: inherit;
}
.logo .logo-txt {
    width: 215px;
}
.flex-spacer {
    flex-grow: 1;
}
.notification {
    border-left: 2px solid #317333;
    border-bottom: 5px solid white;
}
.maintanence {
    border-left: 2px solid #f44336;
    border-bottom: 5px solid white;
}
.notification-bar {
    padding: 3px 16px;
}
.notification-bar .info-txt {
    color: #424242;
}
.navbar-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
}
.navbar-header a {
    color: white;
    text-decoration: none;
}
.menus {
    display: flex;
    height: inherit;
    font-size: 13px;
    margin-left: 12px;
    margin-right: 12px;
}
.user-name {
    padding: 1.2em 0.5em;
}
.divider-right {
    border-left: 1px solid;
    height: 25px;
    padding: 0px;
    margin: auto;
}
.icon-person {
    padding-right: 8px;
}
.items-header {
    margin: 0 1em 0;
    align-items: center;
    display: flex;
    font-size: 13px;
    margin-left: 12px;
    margin-right: 5px;
    font-weight: bold;
}
a.items-header.menu-style:hover {
    color: green;
    text-decoration: underline;
}
.icon {
    display: flex;
    align-items: center;
    padding: 0 6px !important;
    position: relative;
}
.parms-home-link {
    margin-top: 10px;
}
/* Header Global Style end here */
/*** Menu Global Style ends here **/
.subdetail-main {
   /* height: 50px;
    */
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media screen and (min-width: 1024px) {
    .subdetail-main {
        height: 72px;
   }
}
#header-main {
    display: flex;
    justify-content: center;
}
#heading {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 16px;
    margin-left: 16px;
    margin-right: 16px;
    color: #256b0c;
}
.app-name {
    color: #474747;
    font-size: 20px;
    font-weight: bold;
}
/* img {
    height: 50px;
    width: 50px;
} */
.menu-style {
    color: #256b0c;
    font-size: 16px;
}
/*** Menu Global Style ends here **/
.title {
    font-size: 24px;
    color: #333;
}
.primary-header {
    height: 40px;
    margin-top: 15px;
}
.secondary-header {
    color: #2f880f;
    font-weight: bold;
    font-size: normal;
    background-color: #eee;
    border-bottom: 1px solid #a4a4a4;
    margin: 0px;
    width: 100%;
    border-radius: 3px 3px 0px 0px;
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
}
.tertiary-header {
    color: #474747;
    font-weight: bold;
    font-size: normal;
    background-color: #eee;
    border-bottom: 1px solid #a4a4a4;
    width: 100%;
    margin-bottom: 3px;
    border-radius: 3px 3px 0px 0px;
    padding-left: 15px;
    height: 40px;
    line-height: 40px;
}
.column-header {
    color: #333;
    background-color: #eee !important;
    font-weight: bold !important;
    font-size: normal !important;
}
.small-column-header {
    color: #333;
    background-color: #eee !important;
    font-weight: bold !important;
    font-size: normal !important;
    padding-top: 4px !important;
    padding-bottom: 1px !important;
}
.green-column-header {
    color: #2f880f;
    background-color: #eee !important;
    font-weight: bold !important;
    font-size: normal !important;
}
.date-format {
    font-size: 13px;
    color: #474747;
    text-align: center;
    float: right;
    font-weight: bold;
}
/** Footer Global Style Starts here **/
.navbar-footer {
    background-color: #e5e5e5;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 34px;
}
.footer-policy {
    margin-top: 2px;
    margin-top: 5px;
    font-size: 12px;
    color: #296614;
    float: right;
}
.footer-copyright {
    font-size: 12px;
    color: #474747;
    margin-left: 20px;
    margin-right: 20px;
}
.footer-margin {
    margin-top: 5px;
}
/** Footer Global Style Ends here **/
/** Parms-Input for dropdown Global Style Ends here **/
@media screen and (min-width: 1024px) {
    #input-main {
        height: 35px;
        display: flex;
        align-items: center;
   }
}
.primary {
    background-color: green;
    background-image: linear-gradient(to bottom, #008000, #008000);
    color: #fff;
    border-color: green;
    min-width: 70px;
    font-size: 16px;
    font-weight: 600;
}
.k-button:hover, .k-button.k-state-hover {
    border-color: green;
    color: #fff f;
    background-color: green;
    background-image: inherit;
}
.errmsg {
    font-size: 12px;
    margin-left: 5%;
    color: red;
    box-sizing: border-box;
}
.k-list-optionlabel.k-state-selected {
    color: #fff;
    background-color: green !important;
}
.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
    color: #fff;
    background-color: green !important;
}
.k-checkbox:checked {
    border-color: green;
    color: #fff;
    background-color: green !important;
}
.k-checkbox:checked:focus {
    border-color: green;
    box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}
/* .k-grid td {
    border-width: 0;
} */
.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
    color: #656565;
    background-color: white !important;
}
.k-grid tbody tr {
    /* color: #656565; */
    /* background-color: white !important; */
}
.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
    border-color: #a4a4a4;
    padding: 4px 3px;
    width: 19.4em;
    color: #656565;
    background-color: #f6f6f6;
    background-image: linear-gradient(#f6f6f6, #f1f1f1);
}
.k-dropdown, .k-dropdowntree {
    width: 17.4em;
    border-width: 0;
    outline: 0;
    background: none;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    text-align: left;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/** Parms-Input for dropdown Global Style Ends here**/
/** Kendo Grid Panel Global CSS**/
.panel-default > .panel-heading {
    color: #2f880f;
    background-color: #eee;
    border-color: #a4a4a4;
    font-weight: bold;
}
.panel-default {
    border-color: #a4a4a4;
}
.k-tabstrip-items {
    border-color: #333;
}
/* Default Tabstrip Tab State*/
.k-tabstrip-items .k-link {
    padding: 6px 12px;
    color: #2f880f;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    vertical-align: middle;
    flex: 1 1 auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
    background-color: #eee;
    border-color: #a4a4a4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.k-tabstrip-items .k-link .overlay {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.k-tabstrip-items .k-link [aria-hidden="true"] {
    display: none;
}
.k-tabstrip-items .k-link [aria-hidden="false"] {
    display: block;
}
/* Removes border edges that didn't line-up to the active tab edges */
.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
    border-color: transparent;
    line-height: 1.1;
}
/* Active Tabstrip Tab State*/
.k-tabstrip-items .k-state-active .k-link, .k-panelbar > li.k-state-active > .k-link {
    color: #eee;
    background-color: #474747;
}
/** Expand collapse grid icon **/
/** changes the color for complete Grid **/
.k-tabstrip > .k-content {
    border-color: #a4a4a4;
    padding: 0.75%;
}
/** changes the grid background color **/
/** Parameters check box border color **/
/* .panel-default {
    border-color: black;
}
*/
/** Ruleset Grid **/
.k-grid .k-hierarchy-cell {
    text-align: right;
}
.k-grid .k-hierarchy-col {
    width: 5px;
}
.k-widget {
    border-width: 1px;
    border-style: solid;
}
.noscroll {
    overflow: hidden;
}
td.ng-star-inserted .k-i-plus:before, td.ng-star-inserted .k-plus:before {
    content: "\25B6";
}
td.ng-star-inserted .k-i-kpi-trend-equal:before, td.ng-star-inserted .k-i-minus:before, td.ng-star-inserted .k-minus:before {
    content: "\25E2";
}
.k-grid .k-grid-content tr.hide-row {
    display: none;
}
.k-icon k-i-delete ng-star-inserted {
    height: 15px;
    width: 15px;
}
#subGrid .k-grid-content {
    overflow-y: hidden;
}
.k-button ng-star-inserted {
    color: black;
}
.expandCollapse {
    content: '▶';
}
.k-dialog-titlebar {
    background-color: green;
}
.k-button.k-primary:hover, .k-button.k-primary.k-state-hover {
    background-color: green;
}
.k-dialog-buttongroup .k-button.no:hover {
    background-color: red;
}
.actions {
    float: right;
    padding-right: 2px !important;
}
.k-grid th {
    text-overflow: inherit;
}
.row-gap {
    margin-bottom: 15px;
}
.disabled {
    pointer-events: none;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-allowed {
    cursor: pointer;
}
.k-button.k-primary {
    border-color: green;
    color: #fff;
    background-color: green;
    background-image: linear-gradient(rgba(255, 138, 88, 0), rgba(255, 138, 88, 0.2));
}
.k-button.k-primary .k-button.k-state-focused:hover {
    background-color: green;
}
.k-dialog-button-layout-stretched .k-primary:not(:hover):not(.k-state-hover):not(:active):not(.k-state-active) {
    color: GREEN;
}
.grid-wrapper {
    position: relative;
}
.k-i-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 64px;
    background-color: rgba(255, 255, 255, .3);
    color: #ff6757;
}
.k-checkbox:indeterminate {
    color: green;
}
.panel-heading::selection {
    background-color: green;
}
.k-textbox::selection, .k-input::selection, .k-textarea::selection {
    color: #fff;
    background-color: green;
}
k-text-selection ::selection, .k-widget ::selection, .k-block ::selection, .k-panel ::selection, .k-pane-wrapper ::selection, .k-view ::selection, .k-flatcolorpicker ::selection, .k-combobox ::selection, .k-dateinput ::selection, .k-datepicker ::selection, .k-datetimepicker ::selection, .k-timepicker ::selection, .k-dropdown ::selection, .k-dropdowntree ::selection, .k-window ::selection, .k-drawer ::selection, .k-breadcrumb ::selection, .k-card ::selection, .k-chat ::selection, .k-chart ::selection, .k-sparkline ::selection, .k-stockchart ::selection {
    background-color: green;
    color: #fff;
}
.full-width-column {
    position: absolute;
}
.row.parms-input {
    margin-top: 0px;
    margin-bottom: 1px;
    width: 98%;
    margin-left: 1%;
}
kendo-dropdown-list {
    margin-top: 10px;
    margin-bottom: 10px;
}
.cursor-req {
    cursor: pointer;
}
.grid-height {
    height: auto;
}
.authenticating-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 2rem;
    background: #fff;
    opacity: 0.8;
}
.header-auth-text {
    font-weight: bold;
    padding-top: 10px;
}
.sign-in-link {
    cursor: pointer;
    vertical-align: middle;
}
.sign-out-link {
    cursor: pointer;
    vertical-align: middle;
}
.card-unauth {
    width: 25%;
    align-self: center;
    text-align: center;
    border-width: 1px;
    border-style: ridge;
    border-color: darkgray;
    margin: 10px;
}
.card-header-unauth {
    height: 40px;
    margin-top: 15px;
    background-color: #2f880f;
    color: white;
    margin: 0px;
    vertical-align: middle;
    padding-top: 5px;
}
.card-body-unauth {
    margin: 10px;
}

.k-treeview-leaf {
    color:black;
  }
    .k-selected{
    background-color: green !important;
    }
  
  .k-input-inner{
    border:1px solid rgba(0,0,0,.125);;
  }  


.k-grid-header .k-grid-content
{
   overflow-x: scroll;
}
 

  